// src/utils/firebaseUtils.js
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, deleteDoc, collection, getDocs } from 'firebase/firestore';

/**
 * Retrieves user data by user ID from Firestore.
 * @param {string} userId - The ID of the user to retrieve.
 * @returns {Promise<Object|null>} - A promise that resolves to the user data or null if not found.
 */
export const getUserDataById = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return userDocSnap.data();
    } else {
      console.warn(`No user found with ID: ${userId}`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * Creates or updates user data in Firestore.
 * @param {string} userId - The ID of the user to create or update.
 * @param {Object} userData - The user data to set.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const setUserData = async (userId, userData) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await setDoc(userDocRef, userData, { merge: true });
    console.log(`User data set for ID: ${userId}`);
  } catch (error) {
    console.error('Error setting user data:', error);
    throw error;
  }
};

/**
 * Updates user data in Firestore.
 * @param {string} userId - The ID of the user to update.
 * @param {Object} userData - The user data to update.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const updateUserData = async (userId, userData) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, userData);
    console.log(`User data updated for ID: ${userId}`);
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

/**
 * Deletes user data from Firestore.
 * @param {string} userId - The ID of the user to delete.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const deleteUserData = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    await deleteDoc(userDocRef);
    console.log(`User data deleted for ID: ${userId}`);
  } catch (error) {
    console.error('Error deleting user data:', error);
    throw error;
  }
};

/**
 * Retrieves all documents from a specified collection in Firestore.
 * @param {string} collectionName - The name of the collection to retrieve.
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of documents.
 */
export const getCollectionData = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName);
    const collectionSnap = await getDocs(collectionRef);
    return collectionSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error(`Error fetching collection data for ${collectionName}:`, error);
    throw error;
  }
};

/**
 * Retrieves a document by ID from a specified collection in Firestore.
 * @param {string} collectionName - The name of the collection.
 * @param {string} docId - The ID of the document to retrieve.
 * @returns {Promise<Object|null>} - A promise that resolves to the document data or null if not found.
 */
export const getDocumentById = async (collectionName, docId) => {
  try {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.warn(`No document found with ID: ${docId} in collection: ${collectionName}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching document with ID: ${docId} from collection: ${collectionName}:`, error);
    throw error;
  }
};