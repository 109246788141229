// src/context/StatsContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import {
  getGoldMedalCount,
  getSilverMedalCount,
  getBronzeMedalCount,
  getTeamForUserId,
} from '../utils/StatsManager';
import {
  getWOfTheYearRecipients,
  getLOfTheYearRecipients,
} from '../utils/StatsManager_wandl';
import { fetchWandLOfTheYear } from '../scripts/fetchWandLOfTheYear';

const StatsContext = createContext();

export const StatsProvider = ({ children, userId }) => {
  const [stats, setStats] = useState({
    goldMedals2021: 0,
    silverMedals2021: 0,
    bronzeMedals2021: 0,
    goldMedals2023: 0,
    silverMedals2023: 0,
    bronzeMedals2023: 0,
    team2021: '',
    team2023: '',
    wOfTheYearRecipients: [],
    lOfTheYearRecipients: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch BO2023 Medals
        const goldMedals2023 = await getGoldMedalCount('BO2023', userId);
        const silverMedals2023 = await getSilverMedalCount('BO2023', userId);
        const bronzeMedals2023 = await getBronzeMedalCount('BO2023', userId);
        const team2023 = await getTeamForUserId(userId, '2023');

        // Fetch BO2021 Medals
        const goldMedals2021 = await getGoldMedalCount('BO2021', userId);
        const silverMedals2021 = await getSilverMedalCount('BO2021', userId);
        const bronzeMedals2021 = await getBronzeMedalCount('BO2021', userId);
        const team2021 = await getTeamForUserId(userId, '2021');

        // Fetch W and L of the Year recipients for all years
        const { wOfTheYear, lOfTheYear } = await fetchWandLOfTheYear();

        setStats({
          goldMedals2021,
          silverMedals2021,
          bronzeMedals2021,
          goldMedals2023,
          silverMedals2023,
          bronzeMedals2023,
          team2021,
          team2023,
          wOfTheYearRecipients: wOfTheYear,
          lOfTheYearRecipients: lOfTheYear,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  return (
    <StatsContext.Provider value={stats}>
      {children}
    </StatsContext.Provider>
  );
};

export const useStats = () => useContext(StatsContext);