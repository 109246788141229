// src/scripts/fetchWandLOfTheYear.js
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';

const getUserData = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      return userDocSnap.data();
    } else {
      console.warn(`No user found with ID: ${userId}`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

export const fetchWandLOfTheYear = async () => {
  try {
    const awardsCollectionRef = collection(db, 'awards');
    const awardsSnapshot = await getDocs(awardsCollectionRef);
    const awardsData = awardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const wOfTheYear = await Promise.all(
      awardsData.filter(award => award.type === 'W').map(async (award) => {
        const userData = await getUserData(award.winner.id);
        return {
          ...award,
          winner: userData ? `${userData.firstName} ${userData.lastName}` : 'Unknown',
        };
      })
    );

    const lOfTheYear = await Promise.all(
      awardsData.filter(award => award.type === 'L').map(async (award) => {
        const userData = await getUserData(award.winner.id);
        return {
          ...award,
          winner: userData ? `${userData.firstName} ${userData.lastName}` : 'Unknown',
        };
      })
    );

    return { wOfTheYear, lOfTheYear };
  } catch (error) {
    console.error('Error fetching W and L of the Year data:', error);
    throw error;
  }
};