import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { UserProvider, useUser } from './context/UserContext.js';
import { ArticlesProvider } from './context/ArticlesContext.js';
import { EventsProvider } from './context/EventsContext.js';
import { StatsProvider } from './context/StatsContext.js';

const AppWithProviders = () => {
  const { userId } = useUser();

  return (
    <StatsProvider userId={userId}>
      <App />
    </StatsProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <ArticlesProvider>
        <EventsProvider>
          <AppWithProviders />
        </EventsProvider>
      </ArticlesProvider>
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();