// src/utils/StatsManager.js
import {
  getGoldMedalCount as getOlympicGoldMedalCount,
  getSilverMedalCount as getOlympicSilverMedalCount,
  getBronzeMedalCount as getOlympicBronzeMedalCount,
  getTeamForUserId as getOlympicTeamForUserId,
} from './StatsManager_Olympics';

// Export Olympic-related functions with more readable names
export const getGoldMedalCount = getOlympicGoldMedalCount;
export const getSilverMedalCount = getOlympicSilverMedalCount;
export const getBronzeMedalCount = getOlympicBronzeMedalCount;
export const getTeamForUserId = getOlympicTeamForUserId;

// Add other non-Olympic related functions here