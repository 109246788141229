// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCfFuU2Q56M2WbcyyMsRc6kwpteYN--S6s",
  authDomain: "bape-olympics.firebaseapp.com",
  databaseURL: "https://bape-olympics-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bape-olympics",
  storageBucket: "bape-olympics.firebasestorage.app",
  messagingSenderId: "533336101740",
  appId: "1:533336101740:web:42af3093b22b81fb75d949",
  measurementId: "G-4E6GKN6XQJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth, collection, addDoc, getDocs };