// src/components/PersistentLayout.js
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import BottomBar from './BottomBar';
import './PersistentLayout.css';

function PersistentLayout() {
  return (
    <div className="persistent-layout">
      <div className="content">
        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </div>
      <BottomBar />
    </div>
  );
}

export default PersistentLayout;