import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUserFirstName, getUserLastName } from '../utils/userGetters';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [profilePicUrl, setProfilePicUrl] = useState(localStorage.getItem('profilePicUrl') || '');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const saveUserId = (id) => {
    setUserId(id);
    localStorage.setItem('userId', id);
  };

  const saveProfilePicUrl = (url) => {
    setProfilePicUrl(url);
    localStorage.setItem('profilePicUrl', url);
  };

  useEffect(() => {
    if (userId && !profilePicUrl) {
      console.log('Fetching profile picture URL from Firebase...');
      const url = `${process.env.PUBLIC_URL}/profilepics/${userId}.png`;
      saveProfilePicUrl(url);
      console.log('Profile picture URL fetched successfully.');
    }
  }, [userId, profilePicUrl]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const fetchedFirstName = await getUserFirstName(userId);
        const fetchedLastName = await getUserLastName(userId);
        setFirstName(fetchedFirstName);
        setLastName(fetchedLastName);
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    if (userId) {
      fetchUserName();
    }
  }, [userId]);

  return (
    <UserContext.Provider value={{ userId, saveUserId, profilePicUrl, saveProfilePicUrl, firstName, lastName }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);