// src/context/ArticlesContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { db, collection, getDocs } from '../firebase';

const ArticlesContext = createContext();

export const ArticlesProvider = ({ children }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchArticles = async () => {
    console.log('Fetching articles from Firebase...');
    setLoading(true);
    const articlesCollection = collection(db, 'articles');
    const articlesSnapshot = await getDocs(articlesCollection);
    const articlesList = articlesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setArticles(articlesList);
    setLoading(false);
    console.log('Articles fetched successfully.');
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  return (
    <ArticlesContext.Provider value={{ articles, loading, fetchArticles }}>
      {children}
    </ArticlesContext.Provider>
  );
};

export const useArticles = () => useContext(ArticlesContext);