// src/components/BottomBar.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaNewspaper, FaChartBar, FaCalendarAlt, FaUser } from 'react-icons/fa';
import './BottomBar.css';

function BottomBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('');

  useEffect(() => {
    // Set the active button based on the current path
    switch (location.pathname) {
      case '/stats':
        setActiveButton('stats');
        break;
      case '/calendar':
        setActiveButton('calendar');
        break;
      case '/profile':
        setActiveButton('profile');
        break;
      default:
        setActiveButton('news');
    }
  }, [location.pathname]);

  const handleButtonClick = (buttonName, path) => {
    setActiveButton(buttonName);
    navigate(path);
  };

  return (
    <div className="bottom-bar">
      <button
        className={`bottom-bar-button ${activeButton === 'news' ? 'active' : ''}`}
        onClick={() => handleButtonClick('news', '/home')}
      >
        <FaNewspaper className="icon" />
      </button>
      <button
        className={`bottom-bar-button ${activeButton === 'stats' ? 'active' : ''}`}
        onClick={() => handleButtonClick('stats', '/stats')}
      >
        <FaChartBar className="icon" />
      </button>
      <button
        className={`bottom-bar-button ${activeButton === 'calendar' ? 'active' : ''}`}
        onClick={() => handleButtonClick('calendar', '/calendar')}
      >
        <FaCalendarAlt className="icon" />
      </button>
      <button
        className={`bottom-bar-button ${activeButton === 'profile' ? 'active' : ''}`}
        onClick={() => handleButtonClick('profile', '/profile')}
      >
        <FaUser className="icon" />
      </button>
    </div>
  );
}

export default BottomBar;