// src/utils/userGetters.js
import { getUserDataById } from './firebaseUtils';

/**
 * Retrieves all user data by user ID.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<Object|null>} - A promise that resolves to the user data or null if not found.
 */
export const getAllUserData = async (userId) => {
  try {
    const userData = await getUserDataById(userId);
    return userData;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

/**
 * Loads user data and sets it in the provided state setter.
 * @param {string} userId - The ID of the user.
 * @param {Function} setUserData - The state setter function to set user data.
 */
export const loadUserData = async (userId, setUserData) => {
  try {
    const data = await getAllUserData(userId);
    if (data) {
      setUserData(data);
    }
  } catch (error) {
    console.error('Error loading user data:', error);
  }
};

/**
 * Retrieves the first name of the user by user ID.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<string|null>} - A promise that resolves to the first name or null if not found.
 */
export const getUserFirstName = async (userId) => {
  try {
    const userData = await getUserDataById(userId);
    return userData ? userData.firstName : null;
  } catch (error) {
    console.error('Error fetching user first name:', error);
    throw error;
  }
};

/**
 * Retrieves the last name of the user by user ID.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<string|null>} - A promise that resolves to the last name or null if not found.
 */
export const getUserLastName = async (userId) => {
  try {
    const userData = await getUserDataById(userId);
    return userData ? userData.lastName : null;
  } catch (error) {
    console.error('Error fetching user last name:', error);
    throw error;
  }
};

/**
 * Retrieves the birth date of the user by user ID.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<string|null>} - A promise that resolves to the birth date or null if not found.
 */
export const getUserBirthDate = async (userId) => {
  try {
    const userData = await getUserDataById(userId);
    return userData ? userData.birthDate : null;
  } catch (error) {
    console.error('Error fetching user birth date:', error);
    throw error;
  }
};

/**
 * Retrieves the height of the user by user ID.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<number|null>} - A promise that resolves to the height or null if not found.
 */
export const getUserHeight = async (userId) => {
  try {
    const userData = await getUserDataById(userId);
    return userData ? userData.heightCm : null;
  } catch (error) {
    console.error('Error fetching user height:', error);
    throw error;
  }
};

/**
 * Retrieves the weight of the user by user ID.
 * @param {string} userId - The ID of the user.
 * @returns {Promise<number|null>} - A promise that resolves to the weight or null if not found.
 */
export const getUserWeight = async (userId) => {
  try {
    const userData = await getUserDataById(userId);
    return userData ? userData.weightkg : null;
  } catch (error) {
    console.error('Error fetching user weight:', error);
    throw error;
  }
};